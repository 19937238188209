const state = {
    unitOptions: [
        {
            name: 'м2',
            html: '/м<sup>2</sup>',
            constructorHtml: 'м<sup>2</sup>',
            id: 1,
        },
        {
            name: 'шт',
            html: '/шт',
            constructorHtml: 'шт',
            id: 2,
        },
        {
            name: 'ед',
            html: '/ед',
            constructorHtml: 'ед',
            id: 3,
        },
        {
            name: 'м3',
            html: '/м<sup>3</sup>',
            constructorHtml: 'м<sup>3</sup>',
            id: 4,
        },
        {
            name: 'м. пог',
            html: '/м. пог',
            constructorHtml: 'м. пог',
            id: 5,
        },
    ],
};
const getters = {};
const actions = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
