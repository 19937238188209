const state = {
    searchQuery: '',
    searchQueryBuffer: '',
    isSearchOpen: false,
};
const getters = {};
const actions = {
    setSearchQuery({ commit }, data) {
        commit('setSearchQuery', data);
    },
    setSearchQueryBuffer({ commit }, data) {
        commit('setSearchQueryBuffer', data);
    },
    setIsSearchOpen({ commit }, data) {
        commit('setIsSearchOpen', data);
    },
};
const mutations = {
    setSearchQuery(state, data) {
        state.searchQuery = data;
    },
    setSearchQueryBuffer(state, data) {
        state.searchQueryBuffer = data;
    },
    setIsSearchOpen(state, data) {
        state.isSearchOpen = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
