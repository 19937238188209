<template>
    <div class="search__block" v-clickoutside="closeSearchField">
        <a href="javascript:void(0);" class="header__nav-item" @click="openSearchField">
            <svg-icon :name="'search'" :width="24" :height="24"></svg-icon>
        </a>
        <div class="search__field" :class="{'opened': isSearchOpen}">
            <input
                type="text"
                placeholder="8(___)___-__-__"
                v-model="search"
                v-mask="{mask: '8(999)999-99-99', showMaskOnHover: false}"
                @keyup.enter="startSearch"
                ref="searchField">
            <div class="df">
                <svg-icon
                :name="'cancel'"
                :width="16"
                :height="16"
                class="text-color-gray20 clear mr8"
                @click="clearSearch"
                v-if="search.length"></svg-icon>
                <button class="btn" @click="startSearch">
                    <svg-icon :name="'search'" :width="12" :height="12" class="mr4"></svg-icon>
                    Найти
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SearchField',
    data() {
        return {
            search: '',
        };
    },
    watch: {
        searchQuery(val) {
            if (val.length) {
                this.search = this.searchQuery;
                this.openSearchField();
                setTimeout(() => {
                    this.$refs.searchField.select();
                }, 50);
            }
        },
        isSearchOpen(val) {
            if (val) {
                this.$refs.searchField.focus();
            }
        },
    },
    computed: {
        ...mapState({
            searchQuery: (state) => state.search.searchQuery,
            isSearchOpen: (state) => state.search.isSearchOpen,
        }),
        section() {
            if (this.$route.path.indexOf('/moderator') === 0) {
                return 'moderator';
            }
            if (this.$route.path.indexOf('/partner') === 0) {
                return 'partner';
            }
            return '';
        },
        isModeratorPage() {
            return this.$route.path.indexOf('/moderator') === 0;
        },
        isProviderPage() {
            return this.$route.path.indexOf('/partner') === 0;
        },
    },
    methods: {
        openSearchField() {
            this.setIsSearchOpen(true);
        },
        clearSearch() {
            this.search = '';
            this.$refs.searchField.focus();
        },
        ...mapActions('search', [
            'setSearchQuery', 'setIsSearchOpen',
        ]),
        ...mapActions('orderPartner', {
            getSearchItemsPartner: 'getSearchItems',
        }),
        ...mapActions('order', {
            getSearchItemsModerator: 'getSearchItems',
        }),
        startSearch() {
            if (this.search.length) {
                this.setSearchQuery(this.search);
                if (this.isProviderPage) {
                    this.getSearchItemsPartner().then(() => {
                        this.$router.push({ name: 'partner-search' });
                    });
                }
                if (this.isModeratorPage) {
                    this.getSearchItemsModerator().then(() => {
                        this.$router.push({ name: 'moderator-search' });
                    });
                }
                this.closeSearchField();
            }
        },
        closeSearchField() {
            this.setIsSearchOpen(false);
            this.setSearchQuery('');
        },
    },
    directives: {
        clickoutside: {
            mounted(el, binding) {
                const { bubble } = binding.modifiers;
                const handler = (e) => {
                    if (bubble
                        || ((!el.contains(e.target) && el !== e.target)
                        && (!e.target.classList.contains('click-outside-tooltip')))
                    ) {
                        binding.value(e);
                    }
                };
                /* eslint no-underscore-dangle: 0 */
                el.__vueClickOutside__ = handler;
                document.addEventListener('mousedown', handler);
            },

            unmounted(el) {
                document.removeEventListener('mousedown', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            },
        },
    },
};
</script>

<style lang="sass" scoped>
.search
    &__block
        position: relative
    &__field
        position: absolute
        right: 0
        top: 8px
        overflow: hidden
        width: 0
        padding: 0
        height: 32px
        display: flex
        box-sizing: border-box
        align-items: center
        justify-content: space-between
        border-radius: 4px
        background-color: #fff
        transition: all .15s ease
        z-index: 5
        &.opened
            width: 228px
            padding: 0 12px
        input
            width: 109px
            height: 23px
            border: none
            font-size: 14px
            line-height: 22px
            +placeholder($gray50, 400, 14px)
        .btn
            padding: 4px 8px
            height: 24px
            font-size: 10px
            line-height: 16px
        .clear
            cursor: pointer
            transition: all .15s ease
            &:hover
                color: $gray
</style>
