<template>
    <div class="status-menu">
        <router-link :class="{'router-link-active': item.id === activeItem}"
                     :to="item.path" class="status-menu__item" v-for="(item,index) in menu" :key="index">
            <span class="name">{{ item.name }}<svg-icon v-if="item.counter" :name="'info2'" :width="14" :height="14"></svg-icon></span>
        </router-link>
    </div>
</template>
<script>

import { mapState } from 'vuex';

export default {
    name: 'HeaderStatusMenu',
    components: {
    },
    props: {
        moderator: {
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            moderatorOrderMenu: [
                {
                    path: '/moderator/orders',
                    name: 'НОВЫЕ',
                },
                {
                    path: '/moderator/orders/in_progress',
                    name: 'В РАБОТЕ',
                },
                {
                    path: '/moderator/orders/postpone',
                    name: 'ОТЛОЖЕНЫ',
                },
                {
                    path: '/moderator/orders/canceled',
                    name: 'ОТМЕНЕНЫ',
                },
                {
                    path: '/moderator/orders/finished',
                    name: 'ЗАВЕРШЕНЫ',
                },
            ],
            moderatorOfferMenu: [
                {
                    path: '/moderator/offers',
                    name: 'В РАЗРАБОТКЕ',
                },
                {
                    path: '/moderator/offers/wait_public',
                    name: 'ОЖИДАЮТ ПУБЛИКАЦИИ',
                },
                {
                    path: '/moderator/offers/public',
                    name: 'ОПУБЛИКОВАННЫЕ',
                },
                {
                    path: '/moderator/offers/paused',
                    name: 'ПРИОСТАНОВЛЕННЫЕ',
                },
            ],
            moderatorPartnerMenu: [
                {
                    path: '/moderator/partners',
                    name: 'АКТИВНЫЕ',
                },
                {
                    path: '/moderator/partners/blocked',
                    name: 'ЗАБЛОКИРОВАННЫЕ',
                },
            ],
            moderatorBuhMenu: [
                {
                    path: '/moderator/buh',
                    name: 'ТЕКУЩИЕ ДОКУМЕНТЫ',
                },
                {
                    path: '/moderator/buh/payed',
                    name: 'ОПЛАЧЕННЫЕ СЧЕТА',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            offerMenu: (state) => state.offerPartner.menuItems,
            counters: (state) => state.counters.items.orders_offers,
        }),
        activeItem() {
            if (this.isPartnerOrderPage || this.isModeratorPartnerOrdersPage) {
                if (this.$route.params.offer) {
                    return this.$route.params.offer;
                } if (this.partnerOrderMenu.length > 0) {
                    return this.partnerOrderMenu[0].id;
                }
            }
            return 0;
        },
        isModeratorOrdersPage() {
            return this.$route.name === 'moderator-orders' || this.$route.name === 'moderator-orders-status';
        },
        isModeratorOffersPage() {
            return this.$route.name === 'moderator-offers' || this.$route.name === 'moderator-offers-status';
        },
        isModeratorPartnerPage() {
            return this.$route.name === 'moderator-partners-home' || this.$route.name === 'moderator-partners';
        },
        isModeratorBuhPage() {
            return this.$route.name === 'moderator-buh-home' || this.$route.name === 'moderator-buh';
        },
        isPartnerOrderPage() {
            return this.$route.name === 'partner-orders'
                || this.$route.name === 'partner-orders-status'
                || this.$route.name === 'partner-orders-status-offer';
        },
        isModeratorPartnerOrdersPage() {
            return this.$route.name === 'moderator-partner-orders'
                || this.$route.name === 'moderator-partner-orders-status'
                || this.$route.name === 'moderator-partner-orders-status-offer';
        },
        countersByStatus() {
            let counters = {};
            for (const c in this.counters) {
                if (c === this.status) {
                    counters = this.counters[c];
                }
            }
            return counters;
        },
        partnerOrderMenu() {
            const menu = [];
            const counters = this.countersByStatus;
            for (const i in this.offerMenu) {
                let counter = 0;
                for (const c in counters) {
                    if (+c === +this.offerMenu[i].id) {
                        counter = counters[c];
                    }
                }
                let path = `/partner/orders/${this.status}/${this.offerMenu[i].id}`;
                if (this.moderator) {
                    path = `/moderator/partner-orders/${this.$route.params.id}/${this.status}/${this.offerMenu[i].id}`;
                }
                menu.push({
                    id: this.offerMenu[i].id,
                    name: `Оффер ${this.offerMenu[i].number}`,
                    number: this.offerMenu[i].number,
                    path,
                    counter,
                });
            }
            menu.sort((a, b) => a.number - b.number);
            return menu;
        },
        menu() {
            if (this.isModeratorOrdersPage) {
                return this.moderatorOrderMenu;
            }
            if (this.isModeratorOffersPage) {
                return this.moderatorOfferMenu;
            }
            if (this.isModeratorPartnerPage) {
                return this.moderatorPartnerMenu;
            }
            if (this.isModeratorBuhPage) {
                return this.moderatorBuhMenu;
            }
            if (this.isPartnerOrderPage || this.isModeratorPartnerOrdersPage) {
                return this.partnerOrderMenu;
            }
            return [];
        },
        status() {
            return this.$route.params.status ? this.$route.params.status : 'new';
        },
    },
};
</script>

<style lang="sass">
.status-menu
    display: flex
    align-items: center
    gap: 16px
    &__item
        color: $gray60
        font-weight: 700
        font-size: 12px
        line-height: 15px
        height: 100%
        padding: 4.5px 2px
        transition: all .15s ease
        &:hover
            color: #fff
        .name
            position: relative
            text-transform: uppercase
            .svg-icon
                color: $yellow
                position: absolute
                right: -10px
                top: -7.5px
        &.router-link-active
            color: $yellow
            .name
                .svg-icon
                    display: none
</style>
