import api from '@/store/api';

const state = {
    items: {
        top: {
            'orders-new': 0,
        },
        orders_offers: [],
    },
};
const getters = {};
const actions = {
    getItems({ commit }, data) {
        const id = data.id ? data.id : '';
        return api.get(`/counter/${id}`, {}).then((r) => {
            commit('setItems', r);
            return r;
        });
    },
};
const mutations = {
    setItems(state, data) {
        state.items = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
