<template>
    <div class="header --plug">
        <div class="header__partner" v-if="isModeratorPartnerOrdersPage"></div>
        <div class="header__top"></div>
        <div class="header__bottom" v-if="showHeaderBottom"></div>
    </div>
    <div class="header">
        <div class="header__partner" v-if="isModeratorPartnerOrdersPage">
            Кабинет подрядчика:<router-link class="text-size-14 text-bold" :to="`/moderator/partners/${statusPartner}/${provider.id}`">
            {{ provider.name }}
        </router-link>
        </div>
        <div class="header__top">
            <div class="inner-wrapper">
                <div class="header__left">
                    <router-link :to="homeLink">
                        <div class="header__logo">
                            <img src="@/assets/img/logo.svg" alt="">
                        </div>
                    </router-link>
                    <header-section-menu :moderator="isModeratorPartnerOrdersPage"></header-section-menu>
                </div>
                <div class="header__right" v-if="!isModeratorPartnerOrdersPage">
                    <search-field v-if="isProviderPage || isModeratorPage"></search-field>
                    <router-link v-if="isProviderPage && user.manager && user.manager.owner" :to="'/partner/offers'" class="header__nav-item">
                        <svg-icon :name="'file'" :width="24" :height="24"></svg-icon>
                    </router-link>
                    <router-link v-if="isProviderPage && user.manager && user.manager.owner" :to="'/partner/staff'" class="header__nav-item">
                        <svg-icon :name="'staff'" :width="24" :height="24"></svg-icon>
                    </router-link>
                    <router-link v-if="isProviderPage && user.manager && user.manager.owner" :to="'/partner/buh'" class="header__nav-item">
                        <svg-icon :name="'coins'" :width="24" :height="24"></svg-icon>
                    </router-link>
                    <router-link v-if="isProviderPage" :to="'/partner/rules'" class="header__nav-item">
                        <svg-icon :name="'rules'" :width="24" :height="24"></svg-icon>
                    </router-link>
                    <router-link v-if="isModeratorPage" :to="'/moderator/rules'" class="header__nav-item">
                        <svg-icon :name="'rules'" :width="24" :height="24"></svg-icon>
                    </router-link>
                    <!-- Тултип пользователя -->
                    <router-link :to="'/lk/'" class="header__user">
                        <div class="user-char">{{ user.name.charAt(0) }}</div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="header__bottom" v-if="showHeaderBottom">
            <div class="inner-wrapper">
                <header-status-menu :moderator="isModeratorPartnerOrdersPage"></header-status-menu>
                <button @click="$router.push('/moderator/offers/' + status + '/add')"
                        type="button" name="button" class="btn --mini btn--yellow" v-if="isOffersPage">
                    <svg-icon :name="'plus'" :width="16" :height="16" class="mr4"></svg-icon>
                    СОЗДАТЬ ОФФЕР
                </button>
                <button @click="$router.push('/moderator/partners/' + statusPartner + '/add')"
                        type="button" name="button" class="btn --mini btn--yellow" v-if="isModeratorPartnerPage">
                    <svg-icon :name="'plus'" :width="16" :height="16" class="mr4"></svg-icon>
                    добавить подрядчика
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderSectionMenu from '@/components/HeaderSectionMenu.vue';
import HeaderStatusMenu from '@/components/HeaderStatusMenu.vue';
import SearchField from '@/components/SearchField.vue';
import { mapState } from 'vuex';

export default {
    name: 'MainHeader',
    components: {
        HeaderSectionMenu,
        HeaderStatusMenu,
        SearchField,
    },
    props: {
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapState({
            userGroup: (state) => state.auth.user.groupsCodes,
            user: (state) => state.auth.user,
            offerMenu: (state) => state.offerPartner.menuItems,
            provider: (state) => state.provider.item,
        }),
        isModeratorPartnerOrdersPage() {
            return this.$route.name === 'moderator-partner-orders'
                || this.$route.name === 'moderator-partner-orders-status'
                || this.$route.name === 'moderator-partner-orders-status-offer';
        },
        isOffersPage() {
            return this.$route.name === 'moderator-offers' || this.$route.name === 'moderator-offers-status';
        },
        isModeratorOrdersPage() {
            return this.$route.name === 'moderator-orders' || this.$route.name === 'moderator-orders-status';
        },
        isModeratorOffersPage() {
            return this.$route.name === 'moderator-offers' || this.$route.name === 'moderator-offers-status';
        },
        isModeratorPartnerPage() {
            return this.$route.name === 'moderator-partners-home' || this.$route.name === 'moderator-partners';
        },
        isModeratorBuhPage() {
            return this.$route.name === 'moderator-buh-home' || this.$route.name === 'moderator-buh';
        },
        isPartnerOrderPage() {
            return this.$route.name === 'partner-orders'
                || this.$route.name === 'partner-orders-status'
                || this.$route.name === 'partner-orders-status-offer';
        },
        showHeaderBottom() {
            return this.isModeratorOrdersPage
                || this.isModeratorOffersPage
                || this.isModeratorPartnerPage
                || this.isModeratorBuhPage
                || (this.isModeratorPartnerOrdersPage && this.offerMenu.length > 1)
                || (this.isPartnerOrderPage && this.offerMenu.length > 1);
        },
        status() {
            return this.$route.params.status ? this.$route.params.status : 'new';
        },
        statusPartner() {
            return this.$route.params.status ? this.$route.params.status : 'active';
        },
        isModeratorPage() {
            return this.$route.path.indexOf('/moderator') === 0;
        },
        isBuhPage() {
            return this.$route.path.indexOf('/buh') === 0;
        },
        isProviderPage() {
            return this.$route.path.indexOf('/partner') === 0 || this.isModeratorPartnerOrdersPage;
        },
        isLk() {
            return this.$route.path.indexOf('/lk') === 0;
        },
        is404() {
            return this.$route.path.indexOf('/404page') === 0;
        },
        homeLink() {
            if (this.isModeratorPage) {
                return '/moderator/orders';
            }
            if (this.isBuhPage) {
                return '/buh/new';
            }
            if (this.isProviderPage) {
                return '/partner/orders';
            }
            if (this.isLk || this.is404 || this.$route.path === '/') {
                if (this.userGroup && this.userGroup.moderator) {
                    return '/moderator/orders';
                }
                if (this.userGroup && this.userGroup.accountant) {
                    return '/buh/new';
                }
                if (this.userGroup && this.userGroup.provider) {
                    return '/partner/orders';
                }
                return '/';
            }
            return '/';
        },
    },
};
</script>

<style lang="sass">
.header
    position: fixed
    width: 100%
    left: 0
    right: 0
    top: 0
    z-index: 10
    .inner-wrapper
        display: flex
        align-items: center
        justify-content: space-between
        height: 100%
    &.--plug
        position: static
        .header__top
            background-color: #fff
        .header__bottom
            background-color: #fff
    &__top
        background-color: $black2
        height: 48px
    &__bottom
        height: 40px
        background-color: $black1
    &__logo
        margin-right: 24px
        height: 32px
    &__left
        display: flex
        align-items: center
    &__right
        display: flex
        align-items: center
    &__nav-item
        color: $gray60
        transition: all .15s ease
        width: 40px
        height: 48px
        display: flex
        align-items: center
        justify-content: center
        &:hover
            color: #fff
        &.router-link-active
            color: $yellow
            background-color: $black1
    &__partner
        height: 48px
        background-color: $black1
        border-bottom: 1px solid $black3
        display: flex
        align-items: center
        justify-content: center
        font-weight: bold
        font-size: 18px
        line-height: 29px
        a
            color: $yellow
            margin-left: 4px
            &:hover
                color: $yellow40
    &__user
        width: 40px
        height: 48px
        display: flex
        align-items: center
        justify-content: center
        .user-char
            width: 24px
            height: 24px
            display: block
            border: 1.5px solid $gray60
            color: $gray60
            border-radius: 24px
            display: flex
            align-items: center
            justify-content: center
            font-weight: bold
            font-size: 12px
            transition: all .15s ease
        &:hover
            .user-char
                border-color: #fff
                color: #fff
        &.router-link-active
            background-color: $black1
            .user-char
                border-color: $yellow
                color: $yellow
</style>
