import api from '@/store/api';

const state = {
    items: [],
    item: {},
    load: false,
    loadMore: false,
    popupItem: {},
    page: 1,
    totalOrders: 0,
    totalPages: 0,
    currentOffer: 0,
    shownOffer: [],
};
const getters = {};
const actions = {
    getItems(context, data) {
        context.commit('setItems', []);
        context.commit('setLoad', true);
        context.commit('setPage', 1);
        context.commit('setCurrentOffer', 0);
        context.commit('setShownOffer', []);
        api.get(`/orders/${data.status}`).then((r) => {
            if (data.status === 'canceled' || data.status === 'finished') {
                context.commit('setItems', r.data.data);
                context.commit('setTotalPages', r.data.meta.last_page);
                context.commit('setTotalOrders', r.data.meta.total);
            } else {
                context.commit('setItems', r);
            }
            context.commit('setLoad', false);
        });
    },
    getMoreItems(context, data) {
        if (state.page < state.totalPages) {
            context.commit('setLoadMore', true);
            context.commit('setPage', state.page + 1);
            api.get(
                `/orders/${data.status}`,
                {
                    page: state.page,
                },
            ).then((r) => {
                context.commit('setItems', [...state.items, ...r.data.data]);
                context.commit('setLoadMore', false);
            });
        }
    },
    getMoreNewItems(context, data) {
        if (data.offer_id !== state.currentOffer && !state.shownOffer.includes(data.offer_id)) {
            context.commit('setCurrentOffer', data.offer_id);
            context.commit('setShownOffer', [...state.shownOffer, data.offer_id]);
            context.commit('setPage', 1);
        }
        if (state.page < data.total_pages) {
            context.commit('setLoadMore', true);
            context.commit('setPage', state.page + 1);
            api.get(
                `/more_orders/${data.status}`,
                {
                    page: state.page,
                    offer_id: data.offer_id,
                },
            ).then((r) => {
                const newItems = state.items.reduce((acc, item, index) => {
                    // eslint-disable-next-line no-param-reassign
                    acc = [...acc, { ...item }];
                    if (item.id === data.offer_id) {
                        acc[index].orders = [...acc[index].orders, ...r];
                    }
                    return acc;
                }, []);
                context.commit('setItems', newItems);
                context.commit('setLoadMore', false);
            });
        }
    },
    getSearchItems(context) {
        context.commit('setItems', []);
        context.commit('setLoad', true);
        context.commit('search/setSearchQueryBuffer', context.rootState.search.searchQuery, { root: true });
        return api.get('/moderator_search/', { query: context.rootState.search.searchQuery }).then((r) => {
            context.commit('setItems', r);
            context.commit('search/setSearchQuery', context.rootState.search.searchQuery, { root: true });
            context.commit('setLoad', false);
        });
    },
    sendUserNote(context, data) {
        return api.post('/user_note', {}, data).then((r) => {
            context.commit('appendUserNote', r);
            context.commit('offerPartner/setMenuItemsNote', r, { root: true });
        });
    },
    sendOrderNote(context, data) {
        return api.post(`/order/${data.order.id}/note`, {}, data.data).then((r) => {
            const setData = {
                event: r,
                order: data.order,
                moderator: data.moderator,
            };
            context.commit('appendOrderNote', setData);
        });
    },
    sendOrderStatus(context, data) {
        return api.post(`/order/${data.order.id}/${data.status}`, {}, data.data).then(() => {
            context.commit('removeOrder', data);
        });
    },
    verify(context, data) {
        if (!data.verified) {
            api.post(`/order/${data.order.id}/verify`, {}, data.data).then((r) => {
                context.commit('verify', { order: data.order, event: r });
            });
        }
    },
};
const mutations = {
    setLoad(state, data) {
        state.load = data;
    },
    setLoadMore(state, data) {
        state.loadMore = data;
    },
    setItems(state, data) {
        state.items = data;
    },
    setPage(state, page) {
        state.page = page;
    },
    setTotalOrders(state, totalOrders) {
        state.totalOrders = totalOrders;
    },
    setTotalPages(state, totalPages) {
        state.totalPages = totalPages;
    },
    setCurrentOffer(state, currentOffer) {
        state.currentOffer = currentOffer;
    },
    setShownOffer(state, data) {
        state.shownOffer = data;
    },
    accept(state, data) {
        for (const o in state.items) {
            if (+state.items[o].id === +data.offer_id) {
                for (const i in state.items[o].orders) {
                    if (+state.items[o].orders[i].id === +data.id) {
                        state.items[o].orders[i].accepted = true;
                        break;
                    }
                }
                break;
            }
        }
    },
    verify(state, data) {
        for (const i in state.items) {
            if (+state.items[i].id === +data.order.id) {
                state.items[i].verified = true;
                state.items[i].events.unshift(data.event);
                break;
            }
        }
    },
    removeOrder(state, data) {
        if (data.order.status === 'new' && data.moderator === true) {
            for (const o in state.items) {
                if (+state.items[o].id === +data.order.offer_id) {
                    for (const i in state.items[o].orders) {
                        if (+state.items[o].orders[i].id === +data.order.id) {
                            state.items[o].orders.splice(i, 1);
                            break;
                        }
                    }
                    if (state.items[o].orders.length === 0) {
                        state.items.splice(o, 1);
                    }
                    break;
                }
            }
        } else {
            for (const i in state.items) {
                if (+state.items[i].id === +data.order.id) {
                    state.items.splice(i, 1);
                    break;
                }
            }
        }
    },
    appendOrderNote(state, data) {
        if (data.order.status === 'new' && data.moderator === true) {
            for (const o in state.items) {
                if (+state.items[o].id === +data.order.offer_id) {
                    for (const i in state.items[o].orders) {
                        if (+state.items[o].orders[i].id === +data.order.id) {
                            state.items[o].orders[i].events.unshift(data.event);
                            setTimeout(() => {
                                const currentDetail = document.getElementById(`order-detail${data.order.id}`);
                                const detailHeight = currentDetail.querySelector('.inner').getBoundingClientRect().height;
                                state.items[o].orders[i].max_height = `${detailHeight}px`;
                            }, 30);
                            break;
                        }
                    }
                    break;
                }
            }
        } else {
            for (const i in state.items) {
                if (+state.items[i].id === +data.order.id) {
                    state.items[i].events.unshift(data.event);
                    const currentDetail = document.getElementById(`order-detail${data.order.id}`);
                    const detailHeight = currentDetail.querySelector('.inner').getBoundingClientRect().height;
                    state.items[i].max_height = `${detailHeight}px`;
                    break;
                }
            }
        }
    },
    appendUserNote(state, data) {
        for (const o in state.items) {
            if (+state.items[o].manager.id === +data.user_id) {
                if (state.items[o].manager.notes.length >= 5) {
                    state.items[o].manager.notes.splice(state.items[o].manager.notes.length - 1, 1);
                }
                state.items[o].manager.notes.unshift(data);
            }
        }
    },
    toggle(state, data) {
        if (data.status === 'new') {
            for (const o in state.items) {
                if (+state.items[o].id === +data.offer_id) {
                    for (const i in state.items[o].orders) {
                        if (+state.items[o].orders[i].id === +data.id) {
                            state.items[o].orders[i].opened = !state.items[o].orders[i].opened;
                            if (state.items[o].orders[i].opened) {
                                const currentDetail = document.getElementById(`order-detail${data.id}`);
                                const detailHeight = currentDetail.querySelector('.inner').getBoundingClientRect().height;
                                state.items[o].orders[i].max_height = `${detailHeight}px`;
                            } else {
                                state.items[o].orders[i].max_height = 0;
                            }
                            break;
                        }
                    }
                    break;
                }
            }
        } else {
            for (const i in state.items) {
                if (+state.items[i].id === +data.id) {
                    state.items[i].opened = !state.items[i].opened;
                    if (state.items[i].opened) {
                        const currentDetail = document.getElementById(`order-detail${data.id}`);
                        const detailHeight = currentDetail.querySelector('.inner').getBoundingClientRect().height;
                        state.items[i].max_height = `${detailHeight}px`;
                    } else {
                        state.items[i].max_height = 0;
                    }
                    break;
                }
            }
        }
    },
    setPopupItem(state, data) {
        state.popupItem = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
