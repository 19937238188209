import api from '@/store/api';

const state = {
    items: [],
    itemsForOffer: [],
    item: {},
    load: false,
    popupItem: {},
};
const getters = {};
const actions = {
    getItems(context, data) {
        context.commit('setItems', []);
        context.commit('setLoad', true);
        const params = {};
        if (data.status) {
            params.status = data.status;
        }
        api.get('/provider_moderator/', params).then((r) => {
            context.commit('setItems', r);
            context.commit('setLoad', false);
        });
    },
    getItemsForOffer(context) {
        context.commit('setItemsForOffer', []);
        api.get('/provider_moderator/for_offer', {}).then((r) => {
            context.commit('setItemsForOffer', r);
        });
    },
    getItem(context, data) {
        context.commit('setItem', {});
        context.commit('setLoad', true);
        const withOffer = data.with_offer ? 'with_offer' : '';
        return api.get(`/provider_moderator/${data.id}/${withOffer}`).then((r) => {
            context.commit('setItem', r);
            if (r.offers) {
                context.commit('offerPartner/setMenuItems', r.offers, { root: true });
            }
            context.commit('setLoad', false);
        });
    },
    addProvider(context, data) {
        return api.post('/provider_moderator/', {}, data).then((r) => {
            return r;
        });
    },
    updateProvider(context, data) {
        return api.put(`/provider_moderator/${data.id}/`, {}, data.formData).then((r) => {
            return r;
        });
    },
};
const mutations = {
    setLoad(state, data) {
        state.load = data;
    },
    setItems(state, data) {
        state.items = [];
        for (const i in data) {
            const item = {
                ...data[i],
                statistic: {
                    new: 0,
                    in_progress: 0,
                    postpone: 0,
                    canceled: 0,
                    finished: 0,
                    profit: 0,
                    our_profit: 0,
                    orders: 0,
                },
            };
            for (const o in data[i].offers) {
                if (data[i].offers[o].statistic) {
                    item.statistic.new += +data[i].offers[o].statistic.new;
                    item.statistic.in_progress += +data[i].offers[o].statistic.in_progress;
                    item.statistic.postpone += +data[i].offers[o].statistic.postpone;
                    item.statistic.canceled += +data[i].offers[o].statistic.canceled;
                    item.statistic.finished += +data[i].offers[o].statistic.finished;
                    item.statistic.profit += +data[i].offers[o].statistic.profit;
                    item.statistic.our_profit += +data[i].offers[o].statistic.our_profit;
                    item.statistic.orders += +data[i].offers[o].statistic.new;
                    item.statistic.orders += +data[i].offers[o].statistic.in_progress;
                    item.statistic.orders += +data[i].offers[o].statistic.postpone;
                    item.statistic.orders += +data[i].offers[o].statistic.canceled;
                    item.statistic.orders += +data[i].offers[o].statistic.finished;
                }
            }
            state.items.push(item);
            state.items.sort((a, b) => b.statistic.our_profit - a.statistic.our_profit);
        }
    },
    setItemsForOffer(state, data) {
        state.itemsForOffer = data;
    },
    setPopupItem(state, data) {
        state.popupItem = data;
    },
    setItem(state, data) {
        state.item = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
