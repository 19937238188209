import api from '@/store/api';

const state = {
    items: [],
    itemsSearch: [],
    item: {},
    load: true,
    loadMore: false,
    loadSearch: false,
    sort: 'asc',
    popupItem: {},
    page: 1,
    totalOrders: 0,
    totalPages: 0,
};
const getters = {};
const actions = {
    getItems(context, data) {
        if (data.offer) {
            context.commit('setItems', []);
            context.commit('setLoad', true);
            context.commit('setPage', 1);
            api.get(
                `/partner_orders/${data.status}`,
                {
                    offer: data.offer,
                    direction: state.sort,
                },
            ).then((r) => {
                context.commit('setItems', r.data.data);
                context.commit('setTotalPages', r.data.meta.last_page);
                context.commit('setTotalOrders', r.data.meta.total);
                context.commit('setLoad', false);
            });
        }
    },
    getMoreItems(context, data) {
        if (state.page < state.totalPages) {
            context.commit('setLoadMore', true);
            context.commit('setPage', state.page + 1);
            api.get(
                `/partner_orders/${data.status}`,
                {
                    offer: data.offer_id,
                    direction: state.sort,
                    page: state.page,
                },
            ).then((r) => {
                context.commit('setItems', [...state.items, ...r.data.data]);
                context.commit('setLoadMore', false);
            });
        }
    },
    getSearchItems(context) {
        context.commit('setItemsSearch', []);
        context.commit('setLoadSearch', true);
        context.commit('search/setSearchQueryBuffer', context.rootState.search.searchQuery, { root: true });
        return api.get('/partner_search/', { query: context.rootState.search.searchQuery }).then((r) => {
            context.commit('setItemsSearch', r);
            context.commit('search/setSearchQuery', context.rootState.search.searchQuery, { root: true });
            context.commit('setLoadSearch', false);
        });
    },
    accept(context, data) {
        if (!data.accepted) {
            api.post(`/partner_order/${data.id}/accept`, {}).then(() => {
                context.commit('accept', data);
            });
        }
    },
    sendUserNote(context, data) {
        api.post('/user_note', {}, data).then((r) => {
            context.commit('appendUserNote', r);
        });
    },
    sendOrderNote(context, data) {
        return api.post(`/partner_order/${data.order.id}/note`, {}, data.data).then((r) => {
            const setData = {
                event: r,
                order: data.order,
            };
            context.commit('appendOrderNote', setData);
        });
    },
    sendOrderStatus(context, data) {
        return api.post(`/partner_order/${data.order.id}/${data.status}`, {}, data.data).then(() => {
            context.commit('removeOrder', data);
        });
    },
};
const mutations = {
    setLoad(state, data) {
        state.load = data;
    },
    setLoadMore(state, data) {
        state.loadMore = data;
    },
    setLoadSearch(state, data) {
        state.loadSearch = data;
    },
    setItems(state, data) {
        state.items = data;
    },
    setItemsSearch(state, data) {
        state.itemsSearch = data;
    },
    setPage(state, page) {
        state.page = page;
    },
    setTotalOrders(state, totalOrders) {
        state.totalOrders = totalOrders;
    },
    setTotalPages(state, totalPages) {
        state.totalPages = totalPages;
    },
    accept(state, data) {
        for (const o in state.items) {
            if (+state.items[o].id === +data.id) {
                state.items[o].accepted = true;
                break;
            }
        }
    },
    removeOrder(state, data) {
        for (const i in state.items) {
            if (+state.items[i].id === +data.order.id) {
                state.items.splice(i, 1);
                break;
            }
        }
    },
    appendOrderNote(state, data) {
        for (const i in state.items) {
            if (+state.items[i].id === +data.order.id) {
                state.items[i].events.unshift(data.event);
                setTimeout(() => {
                    const currentDetail = document.getElementById(`order-detail${data.order.id}`);
                    const detailHeight = currentDetail.querySelector('.inner').getBoundingClientRect().height;
                    state.items[i].max_height = `${detailHeight}px`;
                }, 30);
                break;
            }
        }
    },
    appendUserNote(state, data) {
        for (const o in state.items) {
            if (+state.items[o].manager.id === +data.user_id) {
                if (state.items[o].manager.notes.length >= 5) {
                    state.items[o].manager.notes.splice(state.items[o].manager.notes.length - 1, 1);
                }
                state.items[o].manager.notes.unshift(data);
            }
        }
    },
    toggle(state, data) {
        for (const i in state.items) {
            if (+state.items[i].id === +data.id) {
                state.items[i].opened = !state.items[i].opened;
                if (state.items[i].opened) {
                    const currentDetail = document.getElementById(`order-detail${data.id}`);
                    const detailHeight = currentDetail.querySelector('.inner').getBoundingClientRect().height;
                    state.items[i].max_height = `${detailHeight}px`;
                } else {
                    state.items[i].max_height = 0;
                }
                break;
            }
        }
    },
    setPopupItem(state, data) {
        state.popupItem = data;
    },
    setSort(state, data) {
        state.sort = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
