import api from '@/store/api';

const state = {
    items: [],
    item: {},
    load: true,
    popupItem: {},
};
const getters = {};
const actions = {
    getItems(context, data) {
        context.commit('setItems', []);
        context.commit('setLoad', true);
        return api.get('/offer_moderator/', { status: data.status }).then((r) => {
            context.commit('setItems', r);
            context.commit('setLoad', false);
        });
    },
    getItem(context, data) {
        context.commit('setItem', {});
        context.commit('setLoad', true);
        return api.get(`/offer_moderator/${data.id}/`, {}).then((r) => {
            context.commit('setItem', r);
            context.commit('setLoad', false);
        });
    },
    saveOffer(context, data) {
        return api.post('/offer_moderator/', {}, data).then((r) => {
            return r;
        });
    },
    updateOffer(context, data) {
        return api.put(`/offer_moderator/${data.id}`, {}, data.formData).then((r) => {
            return r;
        });
    },
    deleteOffer(context, data) {
        context.commit('deleteOffer', data);
        return api.delete(`/offer_moderator/${data.id}`, {}).then((r) => {
            return r;
        });
    },
    publishOffer(context, data) {
        return api.put(`/offer_moderator/${data.id}/publish`, {}).then((r) => {
            context.commit('deleteOffer', data);
            return r;
        });
    },
    pauseOffer(context, data) {
        return api.put(`/offer_moderator/${data.id}/pause`, { comment: data.comment }).then((r) => {
            context.commit('deleteOffer', data);
            return r;
        });
    },

    promoteOffer(context, data) {
        return api.put(`/offer_moderator/${data.id}/promote`, { period: data.period }).then((r) => {
            context.commit('setPromoteOffer');
            return r;
        });
    },
    unpromoteOffer(context, data) {
        return api.put(`/offer_moderator/${data.id}/unpromote`).then((r) => {
            context.commit('setUnpromoteOffer');
            return r;
        });
    },

    resumeOffer(context, data) {
        return api.put(`/offer_moderator/${data.id}/resume`, { comment: data.comment }).then((r) => {
            context.commit('deleteOffer', data);
            return r;
        });
    },
    sendOffer(context, data) {
        return api.put(`/offer_moderator/${data.id}/send_to_provider`, {}).then((r) => {
            context.commit('deleteOffer', data);
            return r;
        });
    },
    saveFile(context, data) {
        return api.postFile('/file/', {}, data).then((r) => {
            return r;
        });
    },
};
const mutations = {
    setLoad(state, data) {
        state.load = data;
    },
    setItems(state, data) {
        state.items = data;
    },
    setPopupItem(state, data) {
        state.popupItem = data;
    },
    setItem(state, data) {
        state.item = data;
    },
    deleteOffer(state, data) {
        for (const i in state.items) {
            if (state.items[i].id === data.id) {
                state.items.splice(i, 1);
                break;
            }
        }
    },
    setPromoteOffer(state) {
        state.popupItem.promote = true;
    },
    setUnpromoteOffer(state) {
        state.popupItem.promote = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
