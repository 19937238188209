<template>
    <div class="section-menu">
        <router-link :to="item.path" class="section-menu__item"
                     :class="{'router-link-active': item.id === activeItem}"
                     v-for="(item,index) in menu" :key="index">
            <span class="name">{{ item.name }}<svg-icon v-if="counters.top[item.id]"
                                                        :name="'info2'" :width="14" :height="14"></svg-icon></span>
        </router-link>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: 'HeaderSectionMenu',
    components: {
    },
    props: {
        moderator: {
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            moderatorMenu: [
                {
                    path: '/moderator/orders',
                    name: 'ЗАКАЗЫ',
                    id: 'moderator-orders',
                    default: '/moderator/orders',
                },
                {
                    path: '/moderator/partners',
                    name: 'ПОДРЯДЧИКИ',
                    id: 'moderator-partners',
                },
                {
                    path: '/moderator/offers',
                    name: 'ОФФЕРЫ',
                    id: 'moderator-offers',
                },
                {
                    path: '/moderator/buh',
                    name: 'БУХГАЛТЕРИЯ',
                    id: 'moderator-accountant',
                },
            ],
            buhMenu: [
                {
                    path: '/buh/new',
                    name: 'ТЕКУЩИЕ ДОКУМЕНТЫ',
                    id: 'accountant-current',
                    default: '/buh',
                },
                {
                    path: '/buh/payed',
                    name: 'ОПЛАЧЕННЫЕ СЧЕТА',
                    id: 'accountant-payed',
                },
            ],
            providerMenu: [
                {
                    path: '/partner/orders',
                    name: 'НОВЫЕ ЗАКАЗЫ',
                    id: 'orders-new',
                    default: '/partner/orders',
                },
                {
                    path: '/partner/orders/in_progress',
                    name: 'В РАБОТЕ',
                    id: 'orders-in_progress',
                },
                {
                    path: '/partner/orders/postpone',
                    name: 'ОТЛОЖЕНЫ',
                    id: 'orders-postpone',
                },
                {
                    path: '/partner/orders/canceled',
                    name: 'ОТМЕНЕНЫ',
                    id: 'orders-canceled',
                },
                {
                    path: '/partner/orders/finished',
                    name: 'ЗАВЕРШЕНЫ',
                    id: 'orders-finished',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            counters: (state) => state.counters.items,
        }),
        activeItem() {
            let id = false;
            let defaultItem = false;
            for (const i in this.menu) {
                if (this.menu[i].default === this.$route.path) {
                    defaultItem = this.menu[i].id;
                }
                if (this.$route.path.indexOf(this.menu[i].path) === 0) {
                    id = this.menu[i].id;
                }
            }
            return id || defaultItem;
        },
        menu() {
            if (this.isModeratorPage) {
                return this.moderatorMenu;
            }
            if (this.isBuhPage) {
                return this.buhMenu;
            }

            if (this.isProviderPage) {
                if (this.isModeratorPartnerOrdersPage) {
                    return this.moderatorPartnerOrdersMenu;
                }
                return this.providerMenu;
            }
            return [];
        },
        isModeratorPage() {
            return this.$route.path.indexOf('/moderator') === 0 && !this.isModeratorPartnerOrdersPage;
        },
        isBuhPage() {
            return this.$route.path.indexOf('/buh') === 0;
        },
        isProviderPage() {
            return this.$route.path.indexOf('/partner') === 0 || this.isModeratorPartnerOrdersPage;
        },
        isModeratorPartnerOrdersPage() {
            return this.$route.name === 'moderator-partner-orders'
                || this.$route.name === 'moderator-partner-orders-status'
                || this.$route.name === 'moderator-partner-orders-status-offer';
        },
        moderatorPartnerOrdersMenu() {
            return [
                {
                    path: `/moderator/partner-orders/${this.$route.params.id}`,
                    name: 'НОВЫЕ ЗАКАЗЫ',
                    id: 'orders-new',
                    default: `/moderator/partner-orders/${this.$route.params.id}`,
                },
                {
                    path: `/moderator/partner-orders/${this.$route.params.id}/in_progress/`,
                    name: 'В РАБОТЕ',
                    id: 'orders-in_progress',
                },
                {
                    path: `/moderator/partner-orders/${this.$route.params.id}/postpone/`,
                    name: 'ОТЛОЖЕНЫ',
                    id: 'orders-postpone',
                },
                {
                    path: `/moderator/partner-orders/${this.$route.params.id}/canceled/`,
                    name: 'ОТМЕНЕНЫ',
                    id: 'orders-canceled',
                },
                {
                    path: `/moderator/partner-orders/${this.$route.params.id}/finished/`,
                    name: 'ЗАВЕРШЕНЫ',
                    id: 'orders-finished',
                },
            ];
        },
    },
};
</script>

<style lang="sass">
.section-menu
    display: flex
    align-items: center
    &__item
        color: $gray60
        font-weight: 700
        font-size: 12px
        line-height: 15px
        height: 100%
        padding: 16px 12px
        transition: all .15s ease
        &:hover
            color: #fff
        .name
            position: relative
            .svg-icon
                color: $yellow
                position: absolute
                right: -10px
                top: -7.5px
        &.router-link-active
            color: $yellow
            background-color: $black1
            .name
                .svg-icon
                    display: none
</style>
