const state = {
    items: {
        takeToWork: {
            name: 'takeToWork',
            show: false,
        },
        addNote: {
            name: 'addNote',
            show: false,
        },
        whatPress: {
            name: 'whatPress',
            show: false,
        },
        postponeOrder: {
            name: 'postponeOrder',
            show: false,
        },
        cancelOrder: {
            name: 'cancelOrder',
            show: false,
        },
        completeOrder: {
            name: 'completeOrder',
            show: false,
        },
        markVerify: {
            name: 'markVerify',
            show: false,
        },
        sendOffer: {
            name: 'sendOffer',
            show: false,
        },
        deleteOffer: {
            name: 'deleteOffer',
            show: false,
        },
        pausePosting: {
            name: 'pausePosting',
            show: false,
        },
        editOffer: {
            name: 'editOffer',
            show: false,
        },
        publishOffer: {
            name: 'publishOffer',
            show: false,
        },
        unpublishOffer: {
            name: 'unpublishOffer',
            show: false,
        },
        changeResponsible: {
            name: 'changeResponsible',
            show: false,
        },
        attentionModal: {
            name: 'attentionModal',
            show: false,
        },
        buhAttentionModal: {
            name: 'buhAttentionModal',
            show: false,
        },
        partnerPublishOffer: {
            name: 'partnerPublishOffer',
            show: false,
        },
        workerModal: {
            name: 'workerModal',
            show: false,
        },
        acceptDocs: {
            name: 'acceptDocs',
            show: false,
        },
        cancelDocs: {
            name: 'cancelDocs',
            show: false,
        },
        warningModal: {
            name: 'warningModal',
            show: false,
        },
        markAsPaid: {
            name: 'markAsPaid',
            show: false,
        },
        fileErrorSize: {
            name: 'fileErrorSize',
            show: false,
        },
        fileErrorFormat: {
            name: 'fileErrorFormat',
            show: false,
        },
        returnToWork: {
            name: 'returnToWork',
            show: false,
        },
        promoteOffer: {
            name: 'promoteOffer',
            show: false,
        },
        unpromoteOffer: {
            name: 'unpromoteOffer',
            show: false,
        },
        addLinkModal: {
            name: 'addLinkModal',
            show: false,
        },
    },
    notify: {
        articleMoved: 'Статья успешно перемещена',
    },
};

// getters
const getters = {

};

const actions = {
    open(context, data) {
        if (data.name && state.items[data.name]) {
            if (!context.rootState.isDesktop || data.name !== 'catalogNav') {
                document.getElementsByTagName('body')[0].classList.add('noscroll');
            }
            setTimeout(() => {
                context.commit('open', { name: data.name });
            }, 10);
        }
    },
    close(context, data) {
        if (data.name && state.items[data.name]) {
            setTimeout(() => {
                context.commit('close', { name: data.name });
            }, 10);
        }
    },
    showNotify(context, data) {
        if (data.name && state.items[data.name]) {
            context.commit('showNotify', { name: data.name });
            setTimeout(() => {
                context.commit('close', { name: data.name });
            }, 3000);
        }
    },
};

const mutations = {
    close(state, data) {
        if (data.name && state.items[data.name]) {
            state.items[data.name].show = false;
            document.getElementsByTagName('body')[0].classList.remove('noscroll');
        }
    },
    open(state, data) {
        if (data.name && state.items[data.name]) {
            state.items[data.name].show = true;
        }
    },
    showNotify(state, data) {
        if (data.name && state.items[data.name]) {
            state.items[data.name].show = true;
            setTimeout(() => {
                state.items[data.name].show = false;
            }, 3000);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
