<template>
    <div class="loader-block" v-if="button === 'load'">
         <div class="loader"></div>
     </div>
    <div class="header --plug">
        <div class="header__top">

        </div>
    </div>
    <div class="header --auth">
        <div class="header__top df df-center">
            <div class="text-center text-uppercase">
                <a href="/" class="text-color-gray20 text-size-20 text-bold nohover">ZEMSBAZA</a>
            </div>
        </div>
    </div>
    <div class="container --auth">
        <div class="content pt24">
            <div>
                <div class="auth-block" v-if="!isBanned && !isBannedPermanent && !isNoSuccessSection && !isNoSuccess ">
                    <img src="@/assets/img/logo.svg" alt="" style="width: 80px; height: 62px;"/>
                    <div class="text-bold text-size-32 mt16">
                        Добро пожаловать
                    </div>
                    <div  class="text-size-14 mt16">
                      Для входа укажите свой номер телефона
                    </div>
                    <div class="mt24" style="width: 200px">
                        <input-styled
                              v-model="login.value"
                              :placeholder="'Телефон'"
                              :disabled="step === 2"
                              :isEdit="step === 2"
                              @edit="edit"
                              name="login"
                              :auto-focus="true"
                              :is-block-arrow-phone="true"
                              :is-clear-btn="false"
                              :error-text="'Введите корректный номер телефона'"
                              @enter="send()"
                              :mask="{mask:'8(999)999-99-99',showMaskOnHover: false}"
                              :isError="login.error"
                              @focus="removeError('login')"
                              @input="removeError('login')"
                              :isErrorIcon="true"
                              ref="loginInput"
                              type="tel"
                              inputmode="tel"
                        ></input-styled>
                    </div>

                    <div class="mt16" style="width: 200px" v-if="step===2">
                        <input-styled v-model="password.value"
                                      name="password"
                                      :placeholder="'Код из СМС'"
                                      :placeholderInput="'Код из СМС'"
                                      :isError="password.error"
                                      :mask="{mask: '9',repeat: '4',showMaskOnHover: false,placeholder: '',}"
                                      :errorText="''"
                                      @focus="removeError('password')"
                                      @enter="send()"
                                      @input="removeError('password')"
                                      ref="passwordInput"
                                      :isClearBtn="false"
                                      type="tel"
                                      inputmode="tel"
                        ></input-styled>
                    </div>
                    <div class="mt4 df df-center" v-if="step===2">
                        <div class="dif text-center">
                            <div class="text-color-gray50 text-size-12" v-if="timer.smsBan && !password.error">
                                Новый код отправлен на указанный номер.<br>Повторный запрос возможен через {{ timer.timeBan }} ч.
                            </div>
                            <div class="text-size-12" v-else-if="timer.time === 0 && !password.error">
                                 <a @click="newPassword" href="javascript:void(0);" class="link-color hover">Запросить новый</a>
                            </div>
                            <div v-else-if="!password.error" class="text-color-gray50 text-size-12">Запросить новый через {{ timer.time }} сек.</div>
                        </div>
                    </div>

                    <div class="mt32 text-center auth-btn df df-center">
                        <submit-btn tag="button"
                                    @click="send();"
                                    >
                            <svg-icon :name="'send'" :width="16" :height="16" class="mr4"></svg-icon>
                            продолжить
                        </submit-btn>
                    </div>
                </div>

                <div class="auth-block" v-else-if="isBannedPermanent || isNoSuccess">
                    <svg-icon :name="'cancel'" :width="80" :height="80" class="text-color-red"></svg-icon>
                    <div class="mt12 text-size-24 text-color-red text-bold">
                        У номера {{ login.value ? login.value : setBanPermanentPhone }} нет доступа к ZEMSBAZA
                    </div>
                    <div class="mt8 text-size-14 line-height-22 text-center">
                        Если он должен быть, обратитесь к тому, кто пригласил вас на ZEMSBAZA
                    </div>
                </div>

                <div class="auth-block" v-else-if="isNoSuccessSection">
                    <svg-icon :name="'cancel'" :width="80" :height="80" class="text-color-red"></svg-icon>
                    <div class="mt12 text-size-24 text-color-red text-bold">
                        У вас нет доступа к этому разделу
                    </div>
                    <div class="mt8 text-size-14 line-height-22 text-center">
                        Если он должен быть, обратитесь к тому, кто пригласил вас на ZEMSBAZA
                    </div>
                    <a @click="setIsNoSuccessSection(false);" href="/" class="btn mt24">
                        <svg-icon :name="'send'" :width="16" :height="16" class="mr4"></svg-icon>
                        перейти На главную
                    </a>
                </div>

                <div class="auth-block" v-else>
                    <svg-icon :name="'cancel'" :width="80" :height="80" class="text-color-red"></svg-icon>
                    <div class="mt12 text-size-24 text-color-red text-bold">
                        Слишком много попыток входа!
                    </div>
                    <div class="mt8 text-size-14 line-height-22 text-center">
                        Вы заблокированы на 12 часов. Если у вас что-то срочное,<br>
                        обратитесь к тому, кто пригласил вас на ZEMSBAZA
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import InputStyled from '@/components/forms/InputStyled.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';

export default {
    name: 'AuthPage',
    components: {
        InputStyled,
        SubmitBtn,
    },
    props: {
    },
    data() {
        return {
            login: {
                value: '',
                error: false,
            },
            button: 'normal',
            password: {
                value: '',
                error: false,
            },
            step: 1,
            // contractUser: '',
        };
    },
    created() {
    },
    mounted() {
        document.body.classList.add('blue-bg');
    },
    unmounted() {
        document.body.classList.remove('blue-bg');
    },
    computed: {
        ...mapState({
            timer: (state) => state.auth.forms.auth.timer,
            isBanned: (state) => state.auth.isBanned,
            isBannedPermanent: (state) => state.auth.isBannedPermanent,
            setBanPermanentPhone: (state) => state.auth.setBanPermanentPhone,
            isNoSuccessSection: (state) => state.auth.isNoSuccessSection,
            isNoSuccess: (state) => state.auth.isNoSuccess,
            user: (state) => state.auth.user,
            userGroup: (state) => state.auth.user.groupsCodes,
        }),
        showRoute() {
            return !this.needAuth && !this.isBanned && !this.isNoSuccess && !this.isNoSuccessSection;
        },
        isModeratorPage() {
            return this.$route.path.indexOf('/moderator') === 0;
        },
        isBuhPage() {
            return this.$route.path.indexOf('/buh') === 0;
        },
        isProviderPage() {
            return this.$route.path.indexOf('/partner') === 0;
        },
    },
    methods: {
        ...mapActions('auth', [
            'checkLogin',
            'getToken',
        ]),
        ...mapMutations('auth', [
            'setIsNoSuccessSection',
            'setBanPermanent',
            'setLoaded',
            'clearInterval',
        ]),
        ...mapActions('counters', {
            getCounters: 'getItems',
        }),
        ...mapActions('manager', {
            getManagers: 'getItems',
        }),
        edit() {
            this.clearInterval();
            this.step = 1;
            this.password.error = false;
            this.inputFocus('loginInput');
        },
        removeError(field) {
            this[field].error = false;
        },
        validateLogin() {
            const phone = this.login.value.replace(/\D/g, '');
            if (phone.length < 11 || phone[1] !== '9') {
                this.login.error = true;
                return false;
            }
            return true;
        },
        validatePassword() {
            if (this.password.value.length < 4) {
                this.password.error = true;
                return false;
            }
            return true;
        },
        newPassword() {
            this.password.name = '';
            this.checkLogin({
                login: this.login.value,
            }).catch((error) => {
                if (+error === 409 || +error === 400) {
                    this.login.error = true;
                }
                if (+error === 410) {
                    this.step = 2;
                }
            });
            this.inputFocus('passwordInput');
        },
        send() {
            switch (this.step) {
            case 1:
                if (this.validateLogin()) {
                    this.button = 'load';
                    this.checkLogin({
                        login: this.login.value,
                    }).then(() => {
                        this.step = 2;
                        this.inputFocus('passwordInput');
                        this.button = 'normal';
                    }).catch((error) => {
                        if (+error === 400) {
                            this.login.error = true;
                        }
                        if (+error === 410) {
                            this.step = 2;
                            this.inputFocus('passwordInput');
                        }
                        this.button = 'normal';
                    });
                }
                break;
            case 2:
                if (this.validateLogin() && this.validatePassword()) {
                    this.button = 'load';
                    this.getToken({
                        login: this.login.value,
                        password: this.password.value,
                    }).then(() => {
                        this.button = 'normal';
                        if (this.userGroup.provider) {
                            if (this.user.manager.owner) {
                                this.getManagers({ no_redirect: false }).then(() => {
                                    this.getCounters({}).then(() => {});
                                    this.redirect();
                                    setInterval(() => {
                                        this.getCounters({});
                                    }, 30000);
                                });
                            } else {
                                this.redirect();
                            }
                        } else {
                            this.redirect();
                        }
                    }).catch(() => {
                        this.password.error = true;
                        this.button = 'normal';
                    });
                }
                break;
            default:
                break;
            }
        },
        redirect() {
            this.setLoaded(true);
            this.$nextTick(() => {
                if (this.userGroup && this.userGroup.moderator && this.showRoute
                    && (this.$route.path === '/' || this.$route.name === 'moderator' || !this.isModeratorPage)) {
                    this.$router.push('/moderator/orders');
                } else if (this.userGroup && this.userGroup.accountant && this.showRoute && (this.$route.path === '/' || !this.isBuhPage)) {
                    this.$router.push('/buh/new');
                } else if (this.userGroup && this.userGroup.provider && this.showRoute
                    && (this.$route.path === '/' || this.$route.name === 'partner' || !this.isProviderPage)) {
                    this.$router.push('/partner/orders');
                }
            });
        },
        inputFocus(ref) {
            this.$nextTick(() => {
                if (this.$refs[ref]) {
                    this.$refs[ref].$el.getElementsByClassName('inp-s')[0].focus();
                }
            });
        },
    },
};
</script>

<style lang="sass">
.auth-block
    width: 704px
    height: auto
    padding: 48px 0 48px
    background-color: #fff
    border-radius: 4px
    margin: 24px auto 0
    display: flex
    flex-direction: column
    align-items: center
    box-shadow: 0px 0px 6px rgba(148, 181, 225, 0.35)
    .inp-s
        font-weight: bold
        &::-webkit-input-placeholder
            color: $gray50
            text-overflow: ellipsis
            transition: color ease 0.3s
            opacity: 0

        &:-moz-placeholder
            color: $gray50
            text-overflow: ellipsis
            transition: color ease 0.3s
            opacity: 0

        &::-moz-placeholder
            color: $gray50
            text-overflow: ellipsis
            transition: color ease 0.3s
            opacity: 0

        &:-ms-input-placeholder
            color: $gray50
            text-overflow: ellipsis
            transition: color ease 0.3s
            opacity: 0
        &:focus
            &::-webkit-input-placeholder
                opacity: 1

            &:-moz-placeholder
                opacity: 1

            &::-moz-placeholder
                opacity: 1

            &:-ms-input-placeholder
                opacity: 1
.nohover
  &:hover
    color: $gray20
@keyframes page-loader
    0%
        transform: translate(-100%)
    50%
        transform: translate(0px)
    100%
        transform: translate(100%)
.loader-block
    width: 100%
    position: fixed
    overflow: hidden
    height: 4px
    z-index: 15
    background-color: rgba(#fff, 0.3)
    .loader
        width: 100%
        animation: page-loader 1.5s infinite ease-in-out
        background-color: #fff
        height: 4px
</style>
