export const jsonHeader = {
    'Content-Type': 'application/json;charset=utf-8',
    Accept: 'application/json',
};

export const wwwFormHeader = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Accept: 'application/json',
};

export const fileHeader = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
};
