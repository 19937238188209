import api from '@/store/api';

const state = {
    items: [],
    item: {},
    load: false,
    popupItem: {},
};
const getters = {};
const actions = {
    getItems(context) {
        context.commit('setItems', []);
        context.commit('setLoad', true);
        api.get('/bill_partner/', {}).then((r) => {
            context.commit('setItems', r);
            api.get('/bill_partner/current', {}).then((i) => {
                context.commit('prependItem', i);
                context.commit('setLoad', false);
            });
        });
    },
    getItem(context, data) {
        context.commit('setItem', {});
        context.commit('setLoad', true);
        return api.get(`/bill_partner/${data.id}`, {}).then((r) => {
            context.commit('setItem', r);
            context.commit('setLoad', false);
        });
    },
    markAsAccept(context, data) {
        return api.put(`/bill_partner/${data.id}/accept`, {}).then((r) => {
            context.commit('auth/setBillRedirect', r, { root: true });
            return r;
        });
    },
};
const mutations = {
    setLoad(state, data) {
        state.load = data;
    },
    setItems(state, data) {
        state.items = data;
    },
    prependItem(state, data) {
        state.items.unshift(data);
    },
    setPopupItem(state, data) {
        state.popupItem = data;
    },
    setItem(state, data) {
        state.item = data;
    },
    deleteBill(state, data) {
        for (const i in state.items) {
            if (state.items[i].id === data.id) {
                state.items.splice(i, 1);
                break;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
