import api from '@/store/api';

const state = {
    items: [],
    item: {},
    load: false,
    popupItem: {},
};
const getters = {};
const actions = {
    getItems(context, data) {
        context.commit('setItems', []);
        context.commit('setLoad', true);
        return api.get('/partner_manager', { no_redirect: !!data.no_redirect }).then((r) => {
            context.commit('setItems', r);
            context.commit('setLoad', false);
            return r;
        });
    },
    getItem(context, data) {
        context.commit('setItem', {});
        context.commit('setLoad', true);
        return api.get(`/partner_manager/${data.id}/`, {}).then((r) => {
            context.commit('setItem', r);
            context.commit('setLoad', false);
        });
    },
    addItem(context, data) {
        return new Promise((resolve, reject) => {
            api.post('/partner_manager/', {}, data).then((r) => {
                context.commit('appendItem', r);
                resolve(r);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    updateItem(context, data) {
        return new Promise((resolve, reject) => {
            api.put(`/partner_manager/${data.id}/`, {}, data.formData).then((r) => {
                context.commit('updateItem', r);
                resolve(r);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    blockItem(context, data) {
        return api.post(`/partner_manager/${data.id}/block`, {}, data.formData).then((r) => {
            context.commit('setBlockItem', data);
            return r;
        });
    },
};
const mutations = {
    setLoad(state, data) {
        state.load = data;
    },
    setItems(state, data) {
        state.items = data;
    },
    setPopupItem(state, data) {
        state.popupItem = data;
    },
    setItem(state, data) {
        state.item = data;
    },
    appendItem(state, data) {
        state.items.push(data);
    },
    updateItem(state, data) {
        for (const o in state.items) {
            if (+state.items[o].id === +data.id) {
                state.items[o] = data;
                break;
            }
        }
    },
    setBlockItem(state, data) {
        for (const o in state.items) {
            if (+state.items[o].id === +data.id) {
                state.items[o].user.blocked = true;
                break;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
