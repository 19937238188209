<template>
    <div class="popup__content --no-header">
        <svg-icon :name="'check'" :width="80" :height="80" class="text-color-green"></svg-icon>
        <div class="text-size-26 text-bold text-color-green mt12">
            Внимание!
        </div>
        <div class="text-size-14 text-center mt8">
            <slot>
            </slot>
        </div>
        <button type="button" name="button" class="btn mt32" @click="close(modal)">продолжить</button>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'AttentionModal',
    components: {
    },
    props: {
        modal: {
            default() {
                return false;
            },
        },
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
        }),
    },
    watch: {

    },
    methods: {
        ...mapActions('popups', [
            'close',
        ]),
    },
};
</script>

<style lang="sass">
</style>
