/**
 * Это модуль для авторизации
 * */

import axios from 'axios';
import api from '@/store/api';
import store from '@/store';
import router from '@/router';

const state = {
    forms: {
        auth: {
            timer: {
                time: 0,
                timeDefault: 30,
                intervalId: [],
                smsBan: false,
                timeBan: 12,
            },
        },
    },
    user: {},
    isLoaded: false,
    isBanned: false,
    isBannedPermanent: false,
    setBanPermanentPhone: '',
    isNoSuccess: false,
    isNoSuccessSection: false,
    isGetRefreshToken: false,
    localStorage: {
        refresh_token: false,
    },
    client_id: false,
    client_secret: false,
    countRefresh: 0,
};

// getters
const getters = {
    refresh_token() {
        return store.state.auth.localStorage.refresh_token;
    },
    needAuth() {
        return (!store.state.auth.user.id) && store.state.auth.isLoaded;
    },
    isInGroup: (state) => (code) => {
        return !!state.user.groups.find((group) => group.code === code);
    },
};

const actions = {
    getCsrf() {
        return api.get('/sanctum/csrf-cookie').then(() => {});
    },
    first(context) {
        context.commit('setFirstRedirect', {});
        return api.get('/user/first').then(() => {});
    },
    rule(context) {
        context.commit('setRuleRedirect', {});
        return api.get('/user/rule').then(() => {});
    },
    startTimer(context, data) {
        if ((state.forms.auth.timer.intervalId.length === 1 && state.forms.auth.timer.time === 0) || state.forms.auth.timer.intervalId.length > 1) {
            context.commit('clearInterval');
        }

        if (state.forms.auth.timer.intervalId.length === 0) {
            let time = state.forms.auth.timer.timeDefault;
            if (!!data && data.time) {
                time = data.time;
            }

            context.commit('setTimerTime', time);
            const timer = setInterval(() => {
                if (+state.forms.auth.timer.time > 0) {
                    context.commit('decrementTimerTime');
                } else {
                    context.commit('clearInterval');
                }
            }, 1000);
            context.commit('setInterval', timer);
        }
    },
    getCurrentUser(context) {
        return api.get('/user/profile').then((response) => {
            if (response) {
                context.commit('setUser', { user: response });
                context.commit('refreshCountRefresh');
            } else {
                context.dispatch('clearUserAuthInfo', false);
            }
            context.commit('setLoaded', true);
            return response;
        }).catch(() => {});
    },
    checkLogin(context, data) {
        return api.post('/auth/check', {}, {
            login: data.login,
        }).then((response) => {
            if (typeof response === 'object') {
                if (data.secret) {
                    context.commit('setClient', response);
                }
                if (response.status === 'threshold' && response.threshold > 0) {
                    context.dispatch('startTimer', { time: +response.threshold }).then((r) => r);
                } else {
                    context.dispatch('startTimer', { time: context.state.forms.auth.timer.timeDefault }).then((r) => r);
                }
            }
        }).catch((r) => {
            throw r;
        });
    },
    checkRefreshToken(context) {
        return new Promise((resolve, reject) => {
            if (!context.state.isGetRefreshToken && context.state.localStorage.refresh_token && context.state.countRefresh < 2) {
                context.commit('setIsGetRefreshToken', true);
                context.commit('incrementCountRefresh');
                if (context.state.localStorage.refresh_token) {
                    const params = new FormData();
                    params.append('refresh_token', context.state.localStorage.refresh_token);
                    context.dispatch('getTokenRequest', { params, refresh_token: true }).then(
                        () => { context.commit('setIsGetRefreshToken', false); resolve(); },
                        (error) => { context.commit('setIsGetRefreshToken', false); reject(error); },
                    );
                } else {
                    context.commit('setIsGetRefreshToken', false);
                    resolve();
                }
            } else {
                resolve();
            }
        });
    },
    getToken(context, data) {
        return new Promise((resolve, reject) => {
            const params = new FormData();
            params.append('login', data.login);
            params.append('password', data.password);
            context.dispatch('getTokenRequest', { params }).then(
                () => { resolve(); },
                (error) => { reject(error); },
            );
        });
    },
    getTokenRequest(context, data) {
        return new Promise((resolve, reject) => {
            let path = `${process.env.VUE_APP_API_PATH}/auth/token`;
            if (data.refresh_token) {
                path = `${process.env.VUE_APP_API_PATH}/auth/refresh_token`;
            }
            axios.post(path, data.params, api.axiosConfig())
                .then((response) => {
                    if (typeof response.data === 'object') {
                        if (response.data.data.refresh_token) {
                            const { user } = response.data.data;
                            context.commit('setLocalStorage', { key: 'refresh_token', value: response.data.data.refresh_token });
                            if (user && !data.refresh_token) {
                                context.commit('setUser', { user });
                                context.commit('clearInterval');
                            }
                            if (data.refresh_token) {
                                context.dispatch('getCurrentUser').then((r) => {
                                    if (r.groupsCodes && r.groupsCodes.moderator) {
                                        router.push('/moderator/orders');
                                    } else if (r.groupsCodes && r.groupsCodes.accountant) {
                                        router.push('/buh/new');
                                    } else if (r.groupsCodes && r.groupsCodes.provider) {
                                        router.push('/partner/orders');
                                    }
                                    if (r.groupsCodes && r.groupsCodes.provider) {
                                        if (context.state.user.manager.owner) {
                                            context.dispatch('manager/getItems', {}, { root: true });
                                        }
                                        context.dispatch('counters/getItems', {}, { root: true });
                                        setInterval(() => {
                                            context.dispatch('counters/getItems', {}, { root: true });
                                        }, 30000);
                                    }
                                });
                                context.commit('setLoaded', true);
                            }
                            resolve();
                        }
                    } else {
                        reject();
                    }
                }).catch((response) => {
                    api.errorHandler(response).then(() => {
                        reject(response.response.data.errorCode);
                    });
                });
        });
    },

    logout(context) {
        const id = context.state.user.id;
        context.commit('setUser', { user: {} });
        context.commit('setLoaded', true);
        context.commit('clearForms');
        context.commit('setLocalStorage', { key: 'refresh_token', value: false });
        window.scroll(0, 0);
        if (id) {
            context.commit('setBanPermanent', false);
            context.commit('setIsNoSuccess', false);
            context.commit('setIsNoSuccessSection', false);
            return api.get('/auth/logout').then((r) => r);
        }
        return {};
    },
    clearUserAuthInfo(context) {
        context.commit('setLocalStorage', { key: 'refresh_token', value: false });
        context.commit('setUser', { user: {} });
        context.commit('clearForms');
    },
};

const mutations = {
    setTimerTime(state, data) {
        state.forms.auth.timer.time = data;
    },
    decrementTimerTime(state) {
        if (+state.forms.auth.timer.time > 0) {
            state.forms.auth.timer.time -= 1;
        }
    },
    clearInterval(state) {
        Object.keys(state.forms.auth.timer.intervalId).forEach((i) => {
            clearInterval(state.forms.auth.timer.intervalId[i]);
        });
        state.forms.auth.timer.intervalId = [];
    },
    setInterval(state, data) {
        state.forms.auth.timer.intervalId.push(data);
    },
    setBan(state, data) {
        state.isBanned = data;
    },
    setBanPermanent(state, data) {
        state.isBannedPermanent = data;
    },
    setBanPermanentPhone(state, data) {
        state.setBanPermanentPhone = data;
    },
    setIsNoSuccess(state, data) {
        state.isNoSuccess = data;
    },
    setIsNoSuccessSection(state, data) {
        state.isNoSuccessSection = data;
    },
    setSmsBan(state) {
        state.forms.auth.timer.smsBan = true;
    },
    setIsGetRefreshToken(state, data) {
        state.isGetRefreshToken = data;
    },
    incrementCountRefresh(state) {
        state.countRefresh += 1;
    },
    refreshCountRefresh(state) {
        state.countRefresh = 0;
    },
    clearForms(state) {
        state.forms.auth.timer.time = 0;
        state.forms.auth.timer.smsBan = false;
        state.forms.auth.timer.timeBan = 12;
    },
    setUser(state, data) {
        if (typeof data === 'object' && data.user !== undefined) {
            state.user = data.user;
        }
    },
    setLoaded(state, data) {
        state.isLoaded = data;
    },
    setLocalStorage(state, data) {
        state.localStorage[data.key] = data.value;
    },
    setClient(state, data) {
        state.client_id = data.id;
        state.client_secret = data.secret;
    },
    mark(state, data) {
        state.user.marks[`rule_id_${data}`] = true;
    },
    setTechSettings(state, data) {
        state.user.tech_settings = data;
    },
    setOfferRedirect(state, data) {
        state.user.offer_redirect = data.offer_redirect ? data.offer_redirect : false;
    },
    setFirstRedirect(state, data) {
        state.user.first_redirect = data.first_redirect ? data.first_redirect : false;
    },
    setRuleRedirect(state, data) {
        state.user.rule_redirect = data.rule_redirect ? data.rule_redirect : false;
    },
    setBillRedirect(state, data) {
        state.user.bill_redirect = data.bill_redirect ? data.bill_redirect : false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
