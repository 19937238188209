const state = {
    items: {
        digits: {
            mask: '9',
            repeat: 999,
            greedy: false,
            placeholder: '',
            rightAlign: false,
            showMaskOnHover: false,
        },
        price: {
            alias: 'integer',
            placeholder: '',
            suffix: '\u00A0₽',
            autoGroup: true,
            groupSeparator: ' ',
            max: 9999999,
            allowMinus: false,
            groupSize: 3,
            autoUnmask: true,
            rightAlign: false,
            showMaskOnHover: false,
        },
        percent: {
            mask: '9{1,2}%',
            autoUnmask: true,
            placeholder: '',
            rightAlign: false,
            showMaskOnFocus: true,
            showMaskOnHover: false,
        },
        squareMeter: {
            mask: '9{1,999}\u00A0м2',
            autoUnmask: true,
            placeholder: '',
            rightAlign: false,
            showMaskOnFocus: true,
            showMaskOnHover: false,
        },
        phone: {
            mask: '8(999)999-99-99',
            showMaskOnHover: false,
        },
        bik: {
            mask: '9',
            repeat: '9',
            showMaskOnHover: false,
            placeholder: '',
        },
        account: {
            mask: '9',
            repeat: '20',
            showMaskOnHover: false,
            placeholder: '',
        },
        inn: {
            mask: '9',
            repeat: '10',
            showMaskOnHover: false,
            placeholder: '',
        },
        contractDate: {
            alias: 'datetime',
            inputFormat: 'dd.mm.yyyy',
            showMaskOnHover: false,
            placeholder: '__:__:____',
        },
        innIp: {
            mask: '9',
            repeat: '12',
            showMaskOnHover: false,
            placeholder: '',
        },
        ogrn: {
            mask: '9',
            repeat: '13',
            showMaskOnHover: false,
            placeholder: '',
        },
        ogrnIp: {
            mask: '9',
            repeat: '15',
            showMaskOnHover: false,
            placeholder: '',
        },
        kpp: {
            mask: '9',
            repeat: '9',
            showMaskOnHover: false,
            placeholder: '',
        },
        time: {
            regex: '(1?[0-9]|2[0-3]):[0-5][0-9]',
            showMaskOnHover: false,
            placeholder: '',
        },
    },

};

const getters = {
    price: (state) => {
        const price = { ...state.items.price };
        price.suffix = '\u00A0₽';
        return price;
    },
};

const actions = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
