import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index';
import app from '../main';

const routes = [
    {
        path: '/auth',
        name: 'auth',
        // route level code-splitting
        // this generates a separate chunk (main.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "main" */ '../views/Auth.vue'),
    },
    {
        path: '/lk',
        name: 'lk',
        // route level code-splitting
        // this generates a separate chunk (main.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "main" */ '../views/Lk.vue'),
    },
    {
        path: '/partner/',
        name: 'partner',
        // route level code-splitting
        // this generates a separate chunk (partner.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "partner" */ '../views/partner/Provider.vue'),
        children: [
            {
                path: 'orders',
                name: 'partner-orders',
                // route level code-splitting
                // this generates a separate chunk (partner.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partner" */ '../views/partner/Orders.vue'),
            },
            {
                path: 'orders/:status',
                name: 'partner-orders-status',
                // route level code-splitting
                // this generates a separate chunk (partner.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partner" */ '../views/partner/Orders.vue'),
            },
            {
                path: 'orders/:status/:offer',
                name: 'partner-orders-status-offer',
                // route level code-splitting
                // this generates a separate chunk (partner.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partner" */ '../views/partner/Orders.vue'),
            },
            {
                path: 'offers',
                name: 'partner-offers',
                // route level code-splitting
                // this generates a separate chunk (staff.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "staff" */ '../views/partner/Offers.vue'),
            },
            {
                path: 'offers/:id',
                name: 'partner-offer-detail',
                // route level code-splitting
                // this generates a separate chunk (staff.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "staff" */ '../views/partner/OfferDetail.vue'),
            },
            {
                path: 'staff',
                name: 'partner-staff',
                // route level code-splitting
                // this generates a separate chunk (staff.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "staff" */ '../views/partner/Staff.vue'),
            },
            {
                path: 'buh',
                name: 'partner-buh',
                // route level code-splitting
                // this generates a separate chunk (staff.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "buh" */ '../views/partner/Buh.vue'),
            },
            {
                path: 'buh/:id/act',
                name: 'partner-buh-act',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "buh" */ '../views/partner/DocumentsActOrder.vue'),
            },
            {
                path: 'buh/:id/bill',
                name: 'partner-buh-bill',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "buh" */ '../views/partner/DocumentsBill.vue'),
            },
            {
                path: 'search',
                name: 'partner-search',
                // route level code-splitting
                // this generates a separate chunk (main.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "main" */ '../views/partner/Search.vue'),
            },
            {
                path: 'first',
                name: 'partner-first',
                // route level code-splitting
                // this generates a separate chunk (main.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "main" */ '../views/partner/First.vue'),
            },
            {
                path: 'rules',
                name: 'partner-rules',
                // route level code-splitting
                // this generates a separate chunk (partner.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partner" */ '../views/partner/Rules.vue'),
            },
            {
                path: 'entry-rules',
                name: 'partner-entry-rules',
                // route level code-splitting
                // this generates a separate chunk (partner.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partner" */ '../views/partner/EntryRules.vue'),
            },
        ],
    },
    {
        path: '/partner/buh/documents',
        name: 'partner-buh-documents',
        // route level code-splitting
        // this generates a separate chunk (staff.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "staff" */ '../views/DocumentsFull.vue'),
    },
    {
        path: '/moderator/',
        name: 'moderator',
        // route level code-splitting
        // this generates a separate chunk (moderator.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/Moderator.vue'),
        children: [
            {
                path: 'partner-orders/:id',
                name: 'moderator-partner-orders',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/PartnerOrders.vue'),
            },
            {
                path: 'partner-orders/:id/:status',
                name: 'moderator-partner-orders-status',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/PartnerOrders.vue'),
            },
            {
                path: 'partner-orders/:id/:status/:offer',
                name: 'moderator-partner-orders-status-offer',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/PartnerOrders.vue'),
            },
            {
                path: 'orders',
                name: 'moderator-orders',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/Orders.vue'),
            },
            {
                path: 'orders/:status',
                name: 'moderator-orders-status',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/Orders.vue'),
            },
            {
                path: 'offers',
                name: 'moderator-offers',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/Offers.vue'),
            },
            {
                path: 'offers/:status',
                name: 'moderator-offers-status',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/Offers.vue'),
            },
            {
                path: 'offers/:status/:id',
                name: 'moderator-offers-detail',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/OfferDetail.vue'),
            },
            {
                path: 'offers/:status/:id/edit',
                name: 'moderator-offers-constructor',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/OfferConstructor.vue'),
            },
            {
                path: 'offers/:status/:id/preview',
                name: 'moderator-offers-preview',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/OfferPreview.vue'),
            },
            {
                path: 'offers/:status/preview',
                name: 'moderator-offers-new-preview',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/OfferPreview.vue'),
            },
            {
                path: 'offers/:status/add',
                name: 'moderator-offers-constructor-add',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/OfferConstructor.vue'),
            },
            {
                path: 'partners',
                name: 'moderator-partners-home',
                // route level code-splitting
                // this generates a separate chunk (partners.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partners" */ '../views/moderator/Partners.vue'),
            },
            {
                path: 'partners/:status',
                name: 'moderator-partners',
                // route level code-splitting
                // this generates a separate chunk (partners.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partners" */ '../views/moderator/Partners.vue'),
            },
            {
                path: 'partners/:status/:id',
                name: 'moderator-partners-edit',
                // route level code-splitting
                // this generates a separate chunk (partners.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partners" */ '../views/moderator/PartnerEdit.vue'),
            },
            {
                path: 'partners/:status/add',
                name: 'moderator-partners-add',
                // route level code-splitting
                // this generates a separate chunk (partners.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partners" */ '../views/moderator/PartnerEdit.vue'),
            },
            {
                path: 'buh',
                name: 'moderator-buh-home',
                // route level code-splitting
                // this generates a separate chunk (main.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "main" */ '../views/buh/Buh.vue'),
            },
            {
                path: 'buh/:status',
                name: 'moderator-buh',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/buh/Buh.vue'),
            },
            {
                path: 'buh/:status/:id',
                name: 'moderator-buh-doc',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/DocumentsFull.vue'),
            },
            {
                path: 'search',
                name: 'moderator-search',
                // route level code-splitting
                // this generates a separate chunk (main.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "main" */ '../views/moderator/Search.vue'),
            },
            {
                path: 'rules',
                name: 'moderator-rules',
                // route level code-splitting
                // this generates a separate chunk (partner.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partner" */ '../views/partner/Rules.vue'),
            },
        ],
    },
    {
        path: '/moderator/offer-detail',
        name: 'moderator-offer-detail',
        // route level code-splitting
        // this generates a separate chunk (moderator.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/OfferDetail.vue'),
    },
    {
        path: '/moderator/offer-constructor',
        name: 'moderator-offer-constructor',
        // route level code-splitting
        // this generates a separate chunk (staff.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "moderator" */ '../views/moderator/OfferConstructor.vue'),
    },
    {
        path: '/moderator/partners/:status',
        name: 'moderator-partners',
        // route level code-splitting
        // this generates a separate chunk (staff.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "staff" */ '../views/moderator/Partners.vue'),
    },
    {
        path: '/moderator/partners/partner-edit',
        name: 'moderator-partners-partner-edit',
        // route level code-splitting
        // this generates a separate chunk (staff.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "staff" */ '../views/moderator/PartnerEdit.vue'),
    },
    {
        path: '/buh',
        name: 'buh-home',
        // route level code-splitting
        // this generates a separate chunk (main.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "main" */ '../views/buh/BuhPage.vue'),
        children: [
            {
                path: '',
                name: 'buh-index',
                // route level code-splitting
                // this generates a separate chunk (main.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "main" */ '../views/buh/Buh.vue'),
            },
            {
                path: ':status',
                name: 'buh-status',
                // route level code-splitting
                // this generates a separate chunk (main.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "main" */ '../views/buh/Buh.vue'),
            },
            {
                path: ':status/:id',
                name: 'buh-documents',
                // route level code-splitting
                // this generates a separate chunk (moderator.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "moderator" */ '../views/DocumentsFull.vue'),
            },
        ],
    },
    {
        path: '/ui-board',
        name: 'ui-board',
        // route level code-splitting
        // this generates a separate chunk (main.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "main" */ '../views/UiBoard.vue'),
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return new Promise((resolve) => {
                app.config.globalProperties.emitter.on('triggerScroll', () => {
                    resolve(savedPosition);
                });
            });
        }
        return { left: 0, top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    store.dispatch('cancelTokens/CANCEL_PENDING_REQUESTS');
    next();
});

export default router;
