import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import popups from './modules/popups';
import masks from './modules/masks';
import auth from './modules/auth';
import order from './modules/moderator/order';
import orderPartner from './modules/partner/order';
import offer from './modules/moderator/offer';
import offerPartner from './modules/partner/offer';
import provider from './modules/moderator/provider';
import category from './modules/category';
import accountant from './modules/moderator/accountant';
import accountantPartner from './modules/partner/accountant';
import manager from './modules/partner/manager';
import search from './modules/search';
import counters from './modules/counters';
import options from './modules/options';
import cancelTokens from './modules/cancelTokens';

const dataState = createPersistedState({
    paths: ['auth.localStorage'],
    key: 'zemsBazaVuex',
});

export default createStore({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        popups,
        masks,
        auth,
        order,
        offer,
        provider,
        category,
        accountant,
        orderPartner,
        offerPartner,
        accountantPartner,
        manager,
        search,
        counters,
        options,
        cancelTokens,
    },
    plugins: [dataState],
});
