import api from '@/store/api';

const state = {
    items: [],
    menuItems: [],
    item: {},
    load: false,
    popupItem: {},
};
const getters = {};
const actions = {
    getItems(context) {
        context.commit('setItems', []);
        context.commit('setLoad', true);
        return api.get('/offer_partner', {}).then((r) => {
            context.commit('setItems', r);
            context.commit('setLoad', false);
        });
    },
    getMenu(context) {
        return api.get('/offer_partner_menu').then((r) => {
            context.commit('setMenuItems', r);
        });
    },
    getItem(context, data) {
        context.commit('setItem', {});
        context.commit('setLoad', true);
        return api.get(`/offer_partner/${data.id}/`, {}).then((r) => {
            context.commit('setItem', r);
            context.commit('setLoad', false);
        });
    },
    publishOffer(context, data) {
        return api.put(`/offer_partner/${data.id}/publish`, { manager_id: data.manager_id }).then((r) => {
            context.commit('auth/setOfferRedirect', r, { root: true });
            return r;
        });
    },
    setManager(context, data) {
        return api.put(`/offer_partner/${data.id}/manager`, {}, { manager_id: data.manager_id }).then((r) => {
            context.commit('setManager', r);
            return r;
        });
    },
};
const mutations = {
    setLoad(state, data) {
        state.load = data;
    },
    setItems(state, data) {
        state.items = data;
    },
    setMenuItems(state, data) {
        state.menuItems = data;
    },
    setMenuItemsNote(state, data) {
        for (const o in state.menuItems) {
            if (+state.menuItems[o].manager.id === +data.user_id) {
                if (state.menuItems[o].manager.notes.length >= 5) {
                    state.menuItems[o].manager.notes.splice(state.menuItems[o].manager.notes.length - 1, 1);
                }
                state.menuItems[o].manager.notes.unshift(data);
            }
        }
    },
    setPopupItem(state, data) {
        state.popupItem = data;
    },
    setItem(state, data) {
        state.item = data;
    },
    setManager(state, data) {
        for (const i in state.items) {
            if (state.items[i].id === data.id) {
                state.items[i].managers = data.managers;
                break;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
