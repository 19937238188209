<template>
    <main-header v-if="showRoute && isShowHeader"></main-header>
    <router-view v-if="showRoute"/>
    <auth v-else-if="isUserLoaded"></auth>

    <popup-component
        :show="popups.attentionModal.show" :name="popups.attentionModal.name" :width="640">
        <attention-modal :modal="popups.attentionModal">
            Мы подготовили и оформили ваше предложение для покупателей.<br><br>Первым делом его нужно посмотреть и одобрить,
            <br>
            а затем можно заниматься остальными делами :)
        </attention-modal>
    </popup-component>
    <popup-component
        :show="popups.buhAttentionModal.show" :name="popups.buhAttentionModal.name" :width="640">
        <attention-modal :modal="popups.buhAttentionModal">
            Мы подготовили для вас бухгалтерские документы за этот месяц.<br><br>
            Первым делом их нужно посмотреть и одобрить,<br>а затем можно заниматься остальными делами :)
        </attention-modal>
    </popup-component>
</template>
<script>
import MainHeader from '@/components/MainHeader.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import Auth from '@/views/Auth.vue';
import PopupComponent from '@/components/PopupComponent.vue';
import AttentionModal from '@/components/modals/AttentionModal.vue';

export default {
    name: 'App',
    components: {
        MainHeader,
        Auth,
        PopupComponent,
        AttentionModal,
    },
    props: {
    },
    data() {
        return {
        };
    },
    mounted() {
        this.$router.isReady().then(() => {
            this.getCsrf().then(() => {
                this.getCurrentUser().then(() => {
                    setTimeout(() => {
                        if (this.$route.path === '/') {
                            if (this.userGroup && this.userGroup.moderator && this.showRoute
                                && (this.$route.path === '/' || this.$route.name === 'moderator' || !this.isModeratorPage)) {
                                this.$router.push('/moderator/orders');
                            } else if (this.userGroup && this.userGroup.accountant && this.showRoute && (this.$route.path === '/' || !this.isBuhPage)) {
                                this.$router.push('/buh/new');
                            } else if (this.userGroup && this.userGroup.provider && this.showRoute
                                && (this.$route.path === '/' || this.$route.name === 'partner' || !this.isProviderPage)) {
                                this.$router.push('/partner/orders');
                            }
                        }

                        if (this.userGroup && this.userGroup.provider) {
                            if (this.user.manager.owner) {
                                this.getManagers({ no_redirect: false });
                            }
                            this.getCounters({});
                            setInterval(() => {
                                this.getCounters({});
                            }, 30000);
                        }
                    }, 100);
                }).catch(() => {
                    this.$router.push('/');
                });
            }).catch(() => {
                this.$router.push('/');
            });
        });
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
            isUserLoaded: (state) => state.auth.isLoaded,
            isBanned: (state) => state.auth.isBanned,
            isNoSuccess: (state) => state.auth.isNoSuccess,
            isNoSuccessSection: (state) => state.auth.isNoSuccessSection,
            localStorage: (state) => state.auth.localStorage,
            user: (state) => state.auth.user,
            userGroup: (state) => state.auth.user.groupsCodes,
        }),
        ...mapGetters({
            needAuth: 'auth/needAuth',
        }),
        isAuthPage() {
            return this.$route.name === 'auth';
        },
        isShowHeader() {
            return this.$route.name !== 'auth'
                && this.$route.name !== 'moderator-offer-constructor'
                && this.$route.name !== 'moderator-partners-partner-edit'
                && this.$route.name !== 'moderator-offer-detail'
                && this.$route.name !== 'partner-entry-rules'
                && this.$route.name !== 'partner-buh-documents'
                && this.$route.name !== 'buh-documents';
        },
        showRoute() {
            return !this.needAuth && !this.isBanned && !this.isNoSuccess && !this.isNoSuccessSection && this.isUserLoaded;
        },
        isModeratorPage() {
            return this.$route.path.indexOf('/moderator') === 0;
        },
        isBuhPage() {
            return this.$route.path.indexOf('/buh') === 0;
        },
        isProviderPage() {
            return this.$route.path.indexOf('/partner') === 0;
        },
    },
    methods: {
        ...mapActions('popups', [
            'open',
        ]),
        ...mapActions('auth', [
            'getCsrf',
            'getCurrentUser',
        ]),
        ...mapActions('counters', {
            getCounters: 'getItems',
        }),
        ...mapActions('manager', {
            getManagers: 'getItems',
        }),
    },
};
</script>
<style lang="sass">
@import "~@/assets/sass/style.sass"

</style>
