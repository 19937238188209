const state = {
    cancelTokens: [],
};
const getters = {
    cancelTokens(state) {
        return state.cancelTokens;
    },
};
const actions = {
    CANCEL_PENDING_REQUESTS(context) {
        // Cancel all request where a token exists
        context.state.cancelTokens.forEach((request) => {
            if (request.cancel) {
                request.cancel();
            }
        });

        // Reset the cancelTokens store
        context.commit('CLEAR_CANCEL_TOKENS');
    },
};
const mutations = {
    ADD_CANCEL_TOKEN(state, token) {
        state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
        state.cancelTokens = [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
