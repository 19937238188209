import api from '@/store/api';

const state = {
    items: [],
    item: {},
    load: false,
    popupItem: {},
};
const getters = {};
const actions = {
    getItems(context, data) {
        context.commit('setItems', []);
        context.commit('setLoad', true);
        const params = {};
        if (data.status) {
            params.status = data.status;
        }
        api.get('/bill_moderator/', params).then((r) => {
            context.commit('setItems', r);
            context.commit('setLoad', false);
        });
    },
    getItem(context, data) {
        context.commit('setItem', {});
        context.commit('setLoad', true);
        return api.get(`/bill_moderator/${data.id}`, {}).then((r) => {
            context.commit('setItem', r);
            context.commit('setLoad', false);
        });
    },
    markAsPaid(context, data) {
        return api.put(`/bill_moderator/${data.id}/payed`, {}).then((r) => {
            context.commit('deleteBill', data);
            return r;
        });
    },
};
const mutations = {
    setLoad(state, data) {
        state.load = data;
    },
    setItems(state, data) {
        state.items = data;
    },
    setPopupItem(state, data) {
        state.popupItem = data;
    },
    setItem(state, data) {
        state.item = data;
    },
    deleteBill(state, data) {
        for (const i in state.items) {
            if (state.items[i].id === data.id) {
                state.items.splice(i, 1);
                break;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
