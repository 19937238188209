<template>
    <div class="inp-s-wrap full"
        :class="{fill: filled,
            'inp-disabled': disabled,
            'error': isError,
            active: showLabel,
            focused: focus,
            '--show-placeholder': placeholderInput !== '' && placeholder == '',
            '--has-counter': maxLengthNum,}"
        ref="inpWrap">
        <span class="inp-s-label">{{ placeholder }}</span>
        <input :type="type"
            ref="field"
            :id="inputIdComputed"
            @keyup.enter="enter()"
            @focus="setFocus(true)"
            @blur="setFocus(false)"
            class="inp-s"
            :class="[{ disabled:disabled }, classBtn]"
            :disabled="disabled"
            :value="modelValue"
            v-mask="mask"
            :name="nameInput"
            :placeholder="placeholderInput"
            @input="updateValue($event)"
            @keydown="updateValue($event)"
            @keyup="updateValue($event)"
            @change="updateValue($event)"
            :data-quaforroman="quaforroman"
            :maxlength="maxLengthNum || maxLength"
            :autocomplete="fieldAutocomplete"
            :inputmode="inputmode"
        >

        <span class="inp-s-clear-btn" v-if="isClearBtn" @click="clear">
            <svg-icon :name="'cancel'" :width="16" :height="16" v-if="modelValue.length"></svg-icon>
        </span>

        <span class="inp-s-error"
            v-if="!focus && isError && !(errorText === 'Обязательное поле' && modelValue.toString().length > 0)">{{ errorText }}</span>

        <span class="inp-s-icon">
            <svg-icon :name="'info2'" :width="16" :height="16" v-if="isError && isErrorIcon && !focus" class="text-color-red"></svg-icon>
            <svg-icon :name="'pencil'" :width="16" :height="16" v-if="isEdit" @click="edit" class="edit"></svg-icon>
        </span>

        <span class="inp-s-counter" v-if="maxLengthNum">{{ maxLengthNum - modelValue.length }}</span>

        <span class="inp-s-max-buffer" ref="buffer" v-if="maxTextSize">{{$refs['field']?.value}}</span>
    </div>
</template>

<script>
import { numberFormat } from '@/helpers/formatted';

export default {
    name: 'InputStyled',
    props: {
        modelValue: { default: '' },
        placeholder: { default: '' },
        placeholderInput: { default: '' },
        inputId: { default: '' },
        disabled: { default: false },
        classBtn: { default: '' },
        isError: { default: false },
        isErrorIcon: { default: false },
        isEdit: { default: false },
        isMoney: { default: false },
        maxMoney: { default: 9999999 },
        isValidate: { default: false },
        errorText: { default: '' },
        mask: { default: '' },
        name: { default: 'name' },
        isClearBtn: { default: false },
        maxLength: { default: false },
        maxLengthNum: { default: '' },
        fieldAutocomplete: { default: 'off' },
        type: { default: 'text' },
        autoFocus: { default: false },
        inputmode: { default: '' },
        isBold: { default: false },
        maxTextSize: { default: false },
        quaforroman: { default: false },
    },
    components: {
    },
    data() {
        return {
            focus: false,
            showPlaceholder: false,
            nameInput: '',
            repeatError: false,
            inputValue: '',
        };
    },
    mounted() {
        if (this.name !== 'name') {
            this.nameInput = this.name;
        } else {
            this.nameInput = Math.random().toString(36).substr(2, 5);
        }
        if (this.autoFocus === true) {
            this.inputFocus('field');
        }
    },
    beforeUnmount() {
    },
    emits: ['input', 'enter', 'check', 'focus', 'blur', 'edit', 'update:modelValue', 'paste', 'removeError'],
    computed: {
        showLabel() {
            return this.focus;
        },
        filled() {
            return this.modelValue.toString().length > 0;
        },
        inputIdComputed() {
            return this.inputId ? this.inputId : Math.random().toString(36).substr(2, 5);
        },
    },
    watch: {
        isError(val) {
            if (val) {
                if (this.focus) {
                    this.$emit('removeError', this.name);
                }
                if (this.$el.closest('.popup-wrapper')) {
                    let $top = 0;
                    const pr = this.$el.closest('.popup-wrapper');
                    $top += parseFloat(this.$refs.inpWrap.getBoundingClientRect().top + this.$el.closest('.popup-wrapper').scrollTop) - 10;
                    pr.scrollTo({ top: $top, left: 0, behavior: 'smooth' });
                }
            }
        },
    },
    methods: {
        blockArrow(e) {
            if ((e.keyCode >= 37 && e.keyCode <= 40)) {
                e.preventDefault();
                return false;
            }
            return false;
        },
        updateValue(event) {
            if (this.maxTextSize && this.$refs.buffer.offsetWidth > this.maxTextSize
                && event.keyCode !== 46 && event.keyCode !== 8) {
                event.preventDefault();
                return;
            }
            let v = event.target.value;
            if (this.isMoney) {
                v = v.replace(/[-;":'a-zA-Zа-яА-Я\\=`ё/*++!@#$%^&_№?><\s]/g, '');
                if (+v > this.maxMoney) {
                    v = this.maxMoney;
                    v = numberFormat(v, 0, '', ' ');
                } else if (v !== '') {
                    v = numberFormat(v, 0, '', ' ');
                }
                this.$refs.field.value = v;
            }
            if (this.mask && this.mask.mask === '9{1,2}%') {
                if (+v !== 0) {
                    v = +v;
                    this.$refs.field.value = v;
                }
            }
            this.$emit('update:modelValue', v);
            this.$emit('input');
            this.$emit('removeError', this.name);
        },
        enter() {
            this.$emit('enter');
        },
        check() {
            this.$emit('check');
        },
        clear() {
            this.$refs.field.focus();
            this.$emit('update:modelValue', '');
        },
        setFocus(value) {
            let val = value;
            const self = this;
            val = val || false;
            this.focus = val;
            this.repeatError = false;
            if (val) {
                this.$emit('focus');
                setTimeout(() => {
                    self.showPlaceholder = true;
                }, 50);
            } else {
                this.$emit('blur');
                this.showPlaceholder = false;
            }
            this.$emit('removeError', this.name);
        },
        edit() {
            this.$emit('edit');
        },
        inputFocus(ref) {
            if (this.$refs[ref]) {
                this.$nextTick(() => { this.$refs[ref].focus(); });
            }
        },

    },
};
</script>

<style lang="sass">
.inp-s
    width: 100%
    height: 48px
    background: none
    border: none
    border: 1px solid $gray20
    font-size: 14px
    font-weight: normal
    border-radius: 4px
    padding-left: 8px
    padding-right: 8px
    background-color: #fff
    color: $gray
    z-index: 1
    transition: border-color 0.2s ease
    &::-webkit-input-placeholder
        color: $gray50
        text-overflow: ellipsis
        transition: color ease 0.3s
        opacity: 1

    &:-moz-placeholder
        color: $gray50
        text-overflow: ellipsis
        transition: color ease 0.3s
        opacity: 1

    &::-moz-placeholder
        color: $gray50
        text-overflow: ellipsis
        transition: color ease 0.3s
        opacity: 1

    &:-ms-input-placeholder
        color: $gray50
        text-overflow: ellipsis
        transition: color ease 0.3s
        opacity: 1
    &.disabled
        opacity: 0.5
        &:hover
            border-color: $gray20 !important
    &-icon
        position: absolute
        top: calc(50% - 8px)
        right: 8px
        z-index: 1
        .edit
            color: $gray50
            transition: all .15s ease
            cursor: pointer
            &:hover
                color: $gray
    &-placeholder
        pointer-events: none
    &-wrap
        position: relative
        &.active, &.fill
            .inp-s-label
                position: absolute
                top: -6px
                font-size: 10px
                line-height: 12px
                white-space: nowrap
                font-weight: normal
                text-transform: uppercase
                background-color: #fff
                padding: 0 2px
        &.inp-disabled
            .inp-s-label
                opacity: 0.5
        &.--show-placeholder
            .inp-s
                &::-webkit-input-placeholder
                    opacity: 1 !important
                &:-moz-placeholder
                    opacity: 1 !important
                &::-moz-placeholder
                    opacity: 1 !important
                &:-ms-input-placeholder
                    opacity: 1  !important
        &.--has-counter
            .inp-s
                padding-right: 20px
        &.error:not(.focused)
            .inp-s-label
                color: $red
            .inp-s
                border-color: $red
                color: $red
                &::-webkit-input-placeholder
                    color: $red !important

                &:-moz-placeholder
                    color: $red !important

                &::-moz-placeholder
                    color: $red !important

                &:-ms-input-placeholder
                    color: $red !important
                & + .inp-s-error
                    opacity: 1
                &:hover
                    border-color: $red
    &:hover
        border-color: $link-color
    &-counter
        position: absolute
        top: calc(50% - 6px)
        font-weight: normal !important
        z-index: 2
        right: 8px
        font-size: 10px
        line-height: 12px
        color: $gray50
        opacity: 0
        transition: opacity 0.2s ease
    &:focus
        border-color: $link-color
        & ~ .inp-s-counter
            opacity: 1
    &:active
        & ~ .inp-s-counter
            opacity: 1
    &-clear-btn
        width: 16px
        height: 16px
        position: absolute
        top: calc(50% - 8px)
        right: 8px
        cursor: pointer
        color: $gray50
        transition: all .15s ease
        &:hover
            color: $gray
    &-error
        font-size: 10px
        line-height: 16px
        color: $red
        position: absolute
        right: 0
        top: 100%
    &-label
        position: absolute
        top: calc(50% - 8px)
        white-space: nowrap
        left: 8px
        font-size: 14px
        line-height: 16px
        color: $gray50
        z-index: 2
        transition: all 0.1s ease
        pointer-events: none !important
    &-max-buffer
        opacity: 0
        position: absolute
        left: -1000px
        pointer-events: none
        font-size: 40px
        line-height: 48px
        font-weight: 600
</style>
